import React from 'react';
import '../assets/styles/time.css';

const Time: React.FC = () => {
  return (
    <section id='time' className='time'>
      <div className='time-main-box'>
        <p className='title'>
          Es el tiempo para ser tu <br />
          <span className='text-container'>
            <span className='scribble'>mejor versión</span>
            <span>mejor versión</span>
          </span>
        </p>

        <a
          href='https://wa.me/51940749704'
          target='_blank'
          rel='noreferrer'
          type='submit'
        >
          ¡Regístrate ahora!
        </a>
        <p className='sub-title'>PREPARATE ESTE 2025</p>
      </div>
    </section>
  );
};

export default Time;
